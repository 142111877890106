<template>
  <div class="page-content-wrap">
    <div class="container">
      <div class="post-content-wrap">
        <div class="page-title">
          <h1>Можливо втрачені дози</h1>
        </div>
        <div class="page-content">
          <h3>Що таке «можливо втрачені дози»?</h3>
          <p>Можливо втрачені дози вакцини — це кількість ймовірно утилізованих доз вакцини згідно даних, які публікуються Національною службою здоров’я України. Число зображене на сайті обліковується в автоматичному режимі і є сумою втрачених доз вакцини всіма пунктами вакцинації за весь період вакцинальної кампанії.</p>
          <h3>Звідки береться це число?</h3>
          <p>Кожен флакон вакцини AstraZeneca містить десять доз препарату, які можна використати впродовж шести годин, а залишкові (невикористані) дози мають утилізовуватись. Отже, якщо у один день на одному пункті вакцинації кількість використаних вакцин <u>не є кратною десяти</u>, то залишки вакцини мають бути утилізовані. Число зображене на сайті є сумою можливо втрачених доз на всіх пунктах і за весь період вакцинальної кампанії. Воно обліковується автоматично.</p>
          <p><em><strong>Наприклад:</strong> лікарі Центру первинної медико-санітарної №1 Подільського р-ну м. Києва внесли до електронної системи інформацію про 28 проведених щеплень 31-го березня 2021 року. Відтак, флакон у якомі міститься 10 доз був використаний не повністю, тож дві невикористані у цей день дози мали бути утилізовані.</em></p>
          <img :src="chartImage" alt="">
          <h3>Чому дози вакцини «можливо» втрачені і що це означає?</h3>
          <p>Згідно з даними наданими НСЗУ, станом на початок травня в Україні викинули <strong>понад 45 тисяч доз вакцини</strong>. Це більше ніж населення невеликого міста, наприклад, Боярки, й складає близько 6% від кількості використаних доз вакцин.</p>
          <p>Міністерство охорони здоров’я та НСЗУ не звітують про кількість утилізованих доз вакцини публічно, але у відповідях на наші запити запевняють, що наші дані не відповідають дійсності через начебто некоректне внесення даних до електронної системи охорони здоров’я. Але й будь-яку іншу інформацію щодо втрачених доз вони не надають.</p>
          <p><strong>На нашу думку, це нівелює всі заходи зі збору й обліку даних, унеможливлює оцінку ефективності використання коштів платників податків й будь-який громадський контроль за ходом вакцинації. Як наслідок, це призводить численних корупційних ризиків.</strong></p>
          <p>Тому одна з додаткових цілей Карти — звернути увагу НСЗУ, МОЗ та інших дотичних до процесу збору даних відомств на існуючі прогалини у методиці обліку даних по вакцинації й закликати їх усунути ці недоліки.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chartImage from '../assets/img/chart-image.png';

export default {
  name: "Lost",
  data() {
    return {
      chartImage
    }
  }
}
</script>